import React from "react"
import { Router } from "@reach/router"
import CollectionInstances from "../components/collection-instances"
import CollectionYear from "../components/collection-year"

export default function Collection() {
  return (
    <Router basepath="/collection">
      <CollectionYear path="/:type/year/:year" />
      <CollectionInstances path="/:type/:id" />
    </Router>
  )
}
