import React, { useEffect, useState, useCallback } from "react"
import * as DiscogsUtils from "../helpers/discogs-utils"
import * as Cache from "../helpers/cache"
import { useMediaQuery, useTheme } from "@material-ui/core"
import GridLayout from "../components/grid-layout"
import ItemDrawer from "../components/item-drawer"
import LoadingBackdrop from "../components/loading-backdrop"
import {
  ArtistLabelItem,
  InstanceReleaseEntry,
  InstanceReleaseItem,
  InstanceReleaseList,
} from "../helpers/discogs-types"
import Page from "../components/page"
import { useParams } from "@reach/router"
import { graphql, PageProps, useStaticQuery } from "gatsby"
import { Helmet } from "react-helmet"

interface GetOpts {
  type: string
  id: string
  cursor?: string
}

const getItems = async (opts: GetOpts) => {
  const cursor = opts.cursor || ""
  const { type, id } = opts

  return DiscogsUtils.getItemInstances(type as DiscogsUtils.Types, id, cursor)
}

const setInstancesCache = (instances: InstanceReleaseList, opts: GetOpts) => {
  const { type, id } = opts
  const CACHE_KEY = `instances-${type}-${id}`

  Cache.setItem(CACHE_KEY, JSON.stringify(instances))
}

const getInstancesCache = (opts: GetOpts): InstanceReleaseList | null => {
  const { type, id } = opts
  const CACHE_KEY = `instances-${type}-${id}`

  const json = Cache.getItem(CACHE_KEY)
  if (json) {
    return JSON.parse(json)
  } else {
    return null
  }
}

const setItemCache = (
  item: InstanceReleaseItem | ArtistLabelItem,
  id: string
) => {
  const CACHE_KEY = `item-${id}`

  Cache.setItem(CACHE_KEY, JSON.stringify(item))
}

const getItemCache = (id: string) => {
  const CACHE_KEY = `item-${id}`
  const json = Cache.getItem(CACHE_KEY)
  if (json) {
    return JSON.parse(json)
  } else {
    return null
  }
}

export default function CollectionInstances(props: PageProps) {
  const data = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  const [instances, setInstances] = useState<InstanceReleaseList>([])
  const [nextPage, setNextPage] = useState<string>("")
  const [loadingPage, setLoadingPage] = useState<boolean>(false)
  const [
    selectedInstance,
    setSelectedInstance,
  ] = useState<InstanceReleaseEntry | null>(null)
  const [loadingItem, setLoadingItem] = useState<boolean>(false)
  const [initialized, setInitialized] = useState<boolean>(false)
  const [navTitle, setNavTitle] = useState<string>("")

  const theme = useTheme()
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("xs"))

  const siteTitle = data.site.siteMetadata.title

  const { type, id } = useParams()
  const CACHE_KEY_CURSOR = `instances-nextPage-${type}-${id}`
  const { state: locState } = props.location
  let name = locState?.name || ""

  const loadMoreHandler = useCallback(async () => {
    setLoadingPage(true)
    const opts = { type, id, cursor: nextPage }
    const response = await getItems(opts)

    setInstances(prev => {
      const items = [...prev, ...response.items]
      setInstancesCache(items, opts)
      return items
    })
    setNextPage(response.cursor)
    Cache.setItem(CACHE_KEY_CURSOR, response.cursor)

    setLoadingPage(false)
  }, [nextPage, type, id])

  const loadReleaseHandler = useCallback(
    async (id: string) => {
      if (!loadingItem) {
        setLoadingItem(true)
        const itemKey = "item-" + id
        let release = Cache.getItemFromCache(itemKey) as InstanceReleaseEntry
        if (!release) {
          release = await DiscogsUtils.getReleaseItem(id)
          Cache.saveItemToCache(itemKey, release)
        }
        setLoadingItem(false)
        setSelectedInstance(release)
      }
    },
    [loadingItem]
  )

  const closeDialogHandler = useCallback(() => {
    setSelectedInstance(null)
  }, [])

  useEffect(() => {
    const get = async (opts: GetOpts) => {
      setInitialized(false)
      const items = getInstancesCache(opts)
      if (!items) {
        const response = await getItems(opts)
        setInstances(response.items)
        setInstancesCache(response.items, opts)
        Cache.setItem(CACHE_KEY_CURSOR, response.cursor)
        setNextPage(response.cursor)
      } else {
        setNextPage(Cache.getItem(CACHE_KEY_CURSOR))
        setInstances(items)
      }

      if (!name) {
        const item = getItemCache(id)
        if (!item) {
          const itemResponse = await DiscogsUtils.getItem(
            type as DiscogsUtils.Types,
            id
          )
          setItemCache(itemResponse, id)

          if (["artists", "labels"].indexOf(type) !== -1) {
            setNavTitle((itemResponse as ArtistLabelItem).name)
          } else {
            setNavTitle((itemResponse as InstanceReleaseItem).title)
          }
        } else {
          if (["artists", "labels"].indexOf(type) !== -1) {
            setNavTitle(item.name)
          } else {
            setNavTitle(item.title)
          }
        }
      } else {
        setNavTitle(name)
      }

      setInitialized(true)
    }

    get({ type, id })
  }, [type, id, name])

  return (
    <React.Fragment>
      <Helmet>
        <title>{`${siteTitle} - ${navTitle}`}</title>
      </Helmet>
      <Page navTitle={navTitle}>
        <LoadingBackdrop open={!initialized || loadingItem} />
        <GridLayout
          type="instances"
          items={instances}
          nextPage={nextPage}
          loadMoreHandler={loadMoreHandler}
          loadingPage={loadingPage}
          loadItemHandler={loadReleaseHandler}
          closeDialogHandler={closeDialogHandler}
          selectedItem={selectedInstance}
          showInstanceLink={true}
          isLoadingItem={loadingItem}
          useFullDialog={isSmallScreen}
        />
        <ItemDrawer
          type="instances"
          item={selectedInstance}
          closeHandler={closeDialogHandler}
          useInstanceLink={false}
        />
      </Page>
    </React.Fragment>
  )
}
